let origin = window.ORIGIN;
//点餐套餐
const mealListUrl = `${origin}/gateway/hc-health/meal/mealList`;
//用户加入
const addCartUrl = `${origin}/gateway/hc-health/meal/addGoodsCart`;
//购物车
const myCartUrl = `${origin}/gateway/hc-health/meal/myGoodsCart`;
//下单
const toOrderUrl = `${origin}/gateway/hc-health/meal/submitOrder`;
//我的订单
const myOrderUrl = `${origin}/gateway/hc-health/meal/myOrderList`;
//取消订单
const cancelOrderUrl = `${origin}/gateway/hc-health/meal/cancelOrder`;
//确认收货
const sureOrderUrl = `${origin}/gateway/hc-health/meal/confirmReceive`;
//确认接单
const sureAcceptUrl = `${origin}/gateway/hc-health/meal/orderSend`;
//商家订单列表
const bussiOrderUrl = `${origin}/gateway/hc-health/meal/allOrderList`;

//电话列表
const isTelShow = `${origin}/gateway/blade-system/application/getFunctionSetting`;
//房号列表
const roomListUrl = `${origin}/gateway/hc-mini/user/mini/user-asset-list`;
//用户信息
const userInfoUrl = `${origin}/gateway/hc-mini/user/mini/user-info`;
//返回当前可预约时间
const checkIsOrderUrl = `${origin}/gateway/hc-health/meal/checkIsOrder`;
export {
  mealListUrl,
  roomListUrl,
  userInfoUrl,
  addCartUrl,
  myCartUrl,
  toOrderUrl,
  myOrderUrl,
  cancelOrderUrl,
  sureOrderUrl,
  sureAcceptUrl,
  isTelShow,
  bussiOrderUrl,
  checkIsOrderUrl,
};
